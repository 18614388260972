export default class TimeFunction {
  static timestampToDateStringFormat(timestamp: number) { // change 'timestamp'  to  date strings
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const min = date.getMinutes();

    let addzeromonth = '';
    let addzeroday = '';
    let addzerohour = '';
    let addzeromin = '';

    if (month < 10) {
      addzeromonth = `0${month}`;
    } else {
      addzeromonth = `${month}`;
    }
    if (day < 10) {
      addzeroday = `0${day}`;
    } else {
      addzeroday = `${day}`;
    }
    if (hour < 10) {
      addzerohour = `0${hour}`;
    } else {
      addzerohour = `${hour}`;
    }
    if (min < 10) {
      addzeromin = `0${min}`;
    } else {
      addzeromin = `${min}`;
    }
    const currentDateTime = `${String(year)}-${addzeromonth}-${addzeroday} ${addzerohour}:${addzeromin}`;
    return currentDateTime;
  }

  static dateStringsToTimestamp(date: string, time: string) { // change complete date strings to 'timestamp'
    const completeDateString = `${date}T${time}`;
    const timestamp = (new Date(completeDateString)).getTime();
    return timestamp;
  }

  static toISODateTime(date: string, time: string) { // change complete date strings to 'isoDateTime'
    const timestamp = this.dateStringsToTimestamp(date, time);
    const isoDateTime = new Date(timestamp).toISOString();
    return isoDateTime;
  }

  static dateTimeStringToTimestamp(dateTimeString: string) { // ios string都可
    const timestamp = Date.parse(dateTimeString);
    return timestamp;
  }

  static getOnlyDateString(date: string) {
    const timestamp = this.dateTimeStringToTimestamp(date);
    const dateString = this.getDateStringObject(timestamp / 1000).date;
    return dateString;
  }

  static getDateStringObject(timestamp: number) {
    const dateString = this.timestampToDateStringFormat(timestamp);
    const dateStringArray = dateString.split(' ', 2);

    const object = {
      date: dateStringArray[0],
      time: dateStringArray[1],
    };
    return object;
  }

  static getWeekDay(timestamp: number) {
    const date = new Date(timestamp * 1000);
    const weekday = date.getDay();
    return weekday;
  }
}
