import {
  MonitorMapValue, QuizEventModelv4, SentDataRecord, TimeMapValue,
} from '@/apis/models/QuizEventModel';

export default class QuizEventHandler { // 取Map並轉成js map---------
  static getJsMap(map: Map<string, TimeMapValue> | Map<string, MonitorMapValue>) {
    const transMap = new Map();
    if (Object.keys(map).length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(map)) {
        transMap.set(key, value);
      }
    }
    return transMap;
  }

  static getUnsendNum(timeMap: Map<string, TimeMapValue>) {
    const unsendArr = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const value of QuizEventHandler.getJsMap(timeMap).values()) {
      if (value.duration === 0) {
        unsendArr.push(value);
      }
    }
    return unsendArr.length;
  }

  static getMapData(quizEvent: QuizEventModelv4 | null) {
    let timeMap = new Map<string, TimeMapValue>();
    const mapData = {
      unsendDates: [] as string[] || null,
      expiredDates: [] as string[],
      sentDataList: [] as SentDataRecord[],
      recordCodes: [] as string[],
      sentRecordCodes: [] as string[],
    };
    const scheduleSetting = {
      allowRepeat: true,
      repeatPeriod: 'week', // 'days'
      repeatFrequency: 1,
      weeklyScheduledDay: ['1'],
    };

    if (quizEvent && quizEvent.timeMap) {
      timeMap = QuizEventHandler.getJsMap(quizEvent.timeMap);

      // 取mapData 已過期日期 / 未傳送日期 並做顯示資料處理
      if (timeMap.size > 0) {
        mapData.unsendDates = [];
        mapData.expiredDates = [];
        const sentObject = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const value of timeMap.values()) {
          if (value.duration === 0) {
            if (value.recordCode) {
              const object = {
                scheduledTime: value.scheduledTime,
                recordCode: value.recordCode,
              };
              sentObject.push(object);
            }
          } else if (value.scheduledTime) {
            mapData.expiredDates.push(value.scheduledTime);
          }
        }

        sentObject.sort((a, b) => { // 排好順序
          if (a.scheduledTime && b.scheduledTime) {
            const scheduledTimeA = a.scheduledTime;
            const scheduledTimeB = b.scheduledTime;
            if (a.scheduledTime && b.scheduledTime) {
              if (scheduledTimeA < scheduledTimeB) {
                return -1;
              }
            }
            if (scheduledTimeA > scheduledTimeB) {
              return 1;
            }
          }
          return 0;
        });

        sentObject.forEach((item) => {
          if (item.scheduledTime) {
            mapData.unsendDates.push(item.scheduledTime);
            mapData.recordCodes.push(item.recordCode);
          }
        });

        mapData.expiredDates.sort(); // 排好順序

        // 取顯示用的quizGroups
        if (quizEvent.quizGroups && mapData.expiredDates.length > 0) { // map中有過期的東西的話
          quizEvent.quizGroups.splice(0, mapData.expiredDates.length); // 從頭開始刪除length數量的東西
        }

        // onePush 且 發送完了
        if (!scheduleSetting.allowRepeat && mapData.unsendDates.length === 0) {
          // 沒過期的內容的話 代表是"無排程"
          if (mapData.expiredDates.length > 0) { // 有過期的內容的話 會把quizGroups清空
            quizEvent.quizGroups = [];
          }
        }

        // 沒有排程中的組合內容 就把"週期性推送設定"打開 (但可能派不上用場)
        if (mapData.unsendDates.length === 0) {
          scheduleSetting.allowRepeat = true;
        }
      } else { // get不到mapData 把"週期性推送設定"會打開
        scheduleSetting.allowRepeat = true;
      }
    }
    const result = {
      mapData,
      scheduleSetting,
    };
    return result;
  }

  static getExamData(quizEvent: QuizEventModelv4 | null) {
    if (quizEvent && quizEvent.monitorMap && quizEvent.quizGroups && quizEvent.timeMap) {
      const monitorMap = QuizEventHandler.getJsMap(quizEvent.monitorMap);
      const timeMap = QuizEventHandler.getJsMap(quizEvent.timeMap);

      // eslint-disable-next-line no-restricted-syntax
      for (const monitorKey of monitorMap.keys()) {
        let timeMapKey = '';
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of timeMap.entries()) {
          if (value.recordCode === monitorKey) {
            timeMapKey = key;
          }
        }

        quizEvent.quizGroups.forEach((item) => {
          if (item.uuid === timeMapKey) {
            const value = monitorMap.get(monitorKey);

            if (value && value.noScreenshot && value.noSwitchApp && value.noPhoneCall) {
              item.isExamMode = true;
              console.log(item.metadata.name, item.isExamMode);
            } else {
              item.isExamMode = false;
            }
          }
        });
      }
    }
    const result = quizEvent;
    return result;
  }

  static getSentExamData(quizEvent: QuizEventModelv4 | null, mapData: any) {
    if (quizEvent && quizEvent.monitorMap) {
      const monitorMap = QuizEventHandler.getJsMap(quizEvent.monitorMap);
      // eslint-disable-next-line no-restricted-syntax
      for (const monitorKey of monitorMap.keys()) {
        mapData.sentDataList.forEach((item: any) => {
          if (item.recordCode === monitorKey) {
            const value = monitorMap.get(monitorKey);

            if (value && !value.noScreenshot && !value.noSwitchApp && !value.noPhoneCall) {
              item.quizGroup[0].isExamMode = true;
            } else {
              item.quizGroup[0].isExamMode = false;
            }
          }
        });
      }
    }
    const result = mapData.sentDataList;
    return result;
  }
}
